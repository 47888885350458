import * as React from 'react'
import { EventWrapperProps } from 'react-big-calendar'
import { CalendarViewEvent } from '../../types'

type IProps = EventWrapperProps<CalendarViewEvent> & { children?: React.ReactNode }

const CalendarEventWrapper = (props: IProps) => {
  const { event } = props
  return (
    <div className={`event-${event.type}`}>{props.children}</div>
  )
}

export default CalendarEventWrapper